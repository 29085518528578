import React, {Component} from 'react';

import Partners from "../components/Partners";
import GetInTouch from "../components/GetInTouch"

import {loadPromotions} from "../common/Common";
import {NavLink, withRouter} from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import '../assets/css/Promotions.css';

const parseContent = (content) => {
    let textContent = "No content available";

    try {
        const parsedContent = JSON.parse(content);

        if (parsedContent && parsedContent.blocks && parsedContent.blocks.length > 0) {
            const firstBlock = parsedContent.blocks[0];
            textContent = firstBlock.data ? firstBlock.data.text : firstBlock.text;
        }
    } catch (error) {
        console.error("Error parsing content:", error);
    }

    return textContent;
};

class PromotionsGold extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
        };
    }

    componentDidMount() {
        loadPromotions().then(data => {
            this.setState({promotions: data});
        }).catch(() => null);
    }

    handleOpenModal = (promotions) => {
        this.setState({
            showModal: true,
            selectedPromotions: promotions,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            selectedBlog: null,
        });
    };

    promotionsBody = (promotions) => {
        return promotions.map((promotion, index) => {
            if (promotion.tier && (promotion.tier.toLowerCase() === "gold")) {
                return (
                    <div className="promotion-card" key={promotion.id}>
                        <div className="promotion-card-container animate">
                            <div className={`promo-gradient ${index % 2 === 0 ? 'al-left' : 'al-right'}`}></div>
                            <img 
                                src={promotion.header_image_url} 
                                className="promo-img" 
                                alt={promotion.name} 
                                style={{ width: "100%", height: "500px" }} 
                            />
                            <div className={`text-capitalize heading promo-container ${index % 2 === 0 ? 'align-left' : 'align-right'}`}>
                                <h3 className='promo-heading-tag'>{promotion.name}</h3>
                                <p className='promotion-header-para mt-4'>{parseContent(promotion.content)}</p>
                                <div className="read-more-link-prmbtn">
                                    <button onClick={() => this.handleOpenModal(promotion)} 
                                        style={{ width: '200px', height:'40px' }}
                                        className="btn white-background-button read-btn">
                                            See More
                                    </button>
                                </div>
                                <p className='tax right-margin mt-5'>*T & C Apply</p>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };
    

    render() {
        const {promotions} = this.state;
        const { showModal, selectedPromotions } = this.state;
        return (
            <div>
                <div className="header-container promotion-header">
                    <h1 className="promotions-heading header-heading-main" id='promotions-section-main-heading'>
                        dl club<br/>promotions - Gold Section
                    </h1>
                </div>
                <div className='container mt-5' id="promotions">
                    {this.promotionsBody(promotions)}
                </div>
                <div className='mt-5' id="getintouch">
                    <GetInTouch/>
                </div>
                <div id="partners">
                    <Partners/>
                </div>

                <Modal show={showModal} onHide={this.handleCloseModal} centered>
                    <Modal.Body>
                        <div className='cnt' style={{ backgroundImage: `url(${selectedPromotions && selectedPromotions.header_image_url})` }}>
                        <div className='promo-gradient-modal'></div>
                            <button className="modal-close-btn" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="text-capitalize heading modal-promo-container model-nm-sp-dv">
                            <p className='spc-model-popup-nme'>{selectedPromotions && selectedPromotions.name}</p>
                                <p className='modal-promotion-header-para'>{parseContent(selectedPromotions && selectedPromotions.content)}</p>
                                <div className='d-flex'>
                                    
                                <p className='modal-promotion-price sp-p-prs'> rs. {selectedPromotions && selectedPromotions.price}</p>

                                </div>
                                <div className="read-more-link-promo gap-3 d-flex">
                                    <NavLink to={"/contact-us"}>
                                        <button className='pop-button'>
                                                Book Now
                                        </button>
                                    </NavLink>
                                        <button className='pop-button mt-1'>
                                            <a className='call-numb' href="tel:+94 712 885 885">Call Now</a>       
                                        </button>
                                </div>
                                <p className='modal-tax right-margin mt-5'>*T & C Apply</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default PromotionsGold;