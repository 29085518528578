import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {positions, Provider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {isMobile, isTablet} from 'react-device-detect';
import {getUser} from "./auth/Auth";
import ScrollToTop from './components/ScrollToTop';
import Header from "./views/Header";
import Footer from "./views/Footer";
import Home from "./views/Home";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import Crew from "./views/Crew";
import Blog from "./views/Blog";
import BlogPage from "./views/BlogPage";
import Service from "./views/Service";
import Services from "./views/Services";
import Promotions from './views/Promotions';
import PromotionsGold from './views/Promotions-gold';
import PromotionsSilver from './views/Promotions-silver';
import Login from "./views/Login";
import Admin from "./views/Admin";
import MobileAdmin from "./views/MobileAdmin";
import PrivacyPolicyPage from './views/PrivacyPolicyPage';
import ServiceInner from './views/ServiceInner';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        const options = {
            timeout: 5000,
            position: positions.MIDDLE_RIGHT
        };

        this.state = {options: options, user: undefined};
    }

    componentDidMount() {
        getUser().then((data) => {
            this.setState({user: data});
        }).catch(() => {
            this.setState({user: null});
        });
    }

    render() {
        const {options, user} = this.state;
        if (user !== undefined) {
            return (
                <Router>
                    <ScrollToTop>
                        <div id="header">
                            <Header/>
                        </div>
                        <div id="body">
                            <Provider template={AlertTemplate} {...options} id="body">
                                <Switch>
                                    <Route exact path="/">
                                        <Home/>
                                    </Route>
                                    <Route exact path="/about-us">
                                        <AboutUs/>
                                    </Route>
                                    <Route exact path="/contact-us">
                                        <ContactUs/>
                                    </Route>
                                    <Route exact path="/crew">
                                        <Crew/>
                                    </Route>
                                    <Route exact path="/blog">
                                        <BlogPage/>
                                    </Route>
                                    <Route exact path="/blog/:name">
                                        <Blog/>
                                    </Route>
                                    <Route exact path="/services">
                                        <Services/>
                                    </Route>
                                    <Route exact path="/promotions">
                                        <Promotions/>
                                    </Route>
                                    <Route exact path="/promotions/gold">
                                        <PromotionsGold/>
                                    </Route>
                                    <Route exact path="/promotions/silver">
                                        <PromotionsSilver/>
                                    </Route>
                                    {/* <Route exact path="/service/:slug">
                                        <Service/>
                                    </Route> */}
                                    <Route exact path="/privacy-policy">
                                        <PrivacyPolicyPage/>
                                    </Route>
                                    <Route exact path="/service/:slug">
                                        <ServiceInner/>
                                    </Route>
                                    {/* <Route path="/service/:slug">
                                        <Service/>
                                    </Route> */}
                                    <Route exact path="/login">
                                        <Login/>
                                    </Route>
                                    <Route exact path="/admin">
                                        {user ? (isMobile || isTablet) ? <MobileAdmin/> : <Admin/> :
                                            <Redirect to="/login"/>}
                                    </Route>
                                    <Route path="*">
                                        <Redirect to="/"/>
                                    </Route>
                                </Switch>
                            </Provider>
                        </div>
                        <div id="footer">
                            <Footer/>
                        </div>
                    </ScrollToTop>
                </Router>
            );
        } else {
            return null;
        }
    }
}

export default App;
