import React, {Component} from 'react';
import Blogs from "../components/Blogs";
import GetInTouch from '../components/GetInTouch';
import Partners from '../components/Partners';
import {loadBlogs} from "../common/Common";

import '../assets/css/BlogPage.css';

class BlogPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
        };
    }

    componentDidMount() {
        loadBlogs().then(data => {
            this.setState({ blogs: data });
        });
    }

    render() {
        let {blogs} = this.state;
        return (
            <div>
                <div className="header-container blog-page-header">
                    <h1 className="heading header-heading-main blg-Hdr" id='blogpage-section-main-heading'>
                        Read more to<br/> learn more
                    </h1>
                </div>
                <div className='container bg-page-only' id="blogs">
                    <Blogs blogs={blogs}/>
                </div>
                <div id="getintouch">
                    <GetInTouch/>
                </div>
                <div id="partners">
                    <Partners/>
                </div>
            </div>
        )
    }
}

export default BlogPage;
